type iconProps = {
    className: string
}

const RegisteredListingIcon = ({className}: iconProps) => {
    return (
        <svg viewBox="0 0 65.18 65.18" fill="currentColor" className={className}>
            <path d="M82.21,44.92a.48.48,0,0,0-.53-.48c-.64,0-1.27-.1-1.91-.16-2.13-.2-4.27-.43-6.41-.6a.61.61,0,0,1-.64-.5,20.31,20.31,0,0,0-1.83-4.42A.56.56,0,0,1,71,38c1.8-2.15,3.58-4.31,5.38-6.46a.48.48,0,0,0,0-.7,33.71,33.71,0,0,0-7.15-7.16.51.51,0,0,0-.78,0C66.3,25.49,64.14,27.26,62,29a.58.58,0,0,1-.77.07A18.86,18.86,0,0,0,57,27.35a.79.79,0,0,1-.66-.83c-.25-2.77-.52-5.55-.77-8.32,0-.25-.1-.32-.34-.36a25.41,25.41,0,0,0-3.36-.38,31.13,31.13,0,0,0-7,.38c-.24,0-.33.11-.35.36-.08,1.09-.2,2.17-.3,3.26-.17,1.74-.34,3.48-.5,5.21a.61.61,0,0,1-.52.63,20.27,20.27,0,0,0-4.36,1.81A.62.62,0,0,1,38,29c-2.13-1.78-4.29-3.55-6.43-5.33a.46.46,0,0,0-.69,0,33.71,33.71,0,0,0-7.17,7.14c-.22.3-.26.46,0,.78,1.81,2.14,3.59,4.3,5.38,6.46.15.18.29.33.12.62a23.2,23.2,0,0,0-1.95,4.66.39.39,0,0,1-.4.35l-3.7.33-4.88.46a.4.4,0,0,0-.43.39c-.1.92-.25,1.84-.32,2.76a33.21,33.21,0,0,0,.31,7.52c.05.31.16.43.48.46,2.82.25,5.65.52,8.47.76a.53.53,0,0,1,.51.46,21.49,21.49,0,0,0,1.88,4.51.52.52,0,0,1-.08.67c-1.8,2.16-3.58,4.33-5.38,6.48a.49.49,0,0,0,0,.73,33.75,33.75,0,0,0,7.06,7.11.6.6,0,0,0,.91,0c2.13-1.79,4.29-3.56,6.43-5.34a.47.47,0,0,1,.63-.06,22.16,22.16,0,0,0,4.56,1.89c.23.07.37.16.39.44,0,.61.12,1.23.18,1.84.21,2.2.45,4.39.61,6.58,0,.5.28.58.66.63a30.55,30.55,0,0,0,4.84.34,29.31,29.31,0,0,0,5.16-.38c.19,0,.42,0,.45-.34.08-1.18.2-2.36.32-3.53.15-1.67.31-3.33.46-5a.58.58,0,0,1,.49-.6,20.63,20.63,0,0,0,4.42-1.84A.57.57,0,0,1,62,71c2.14,1.78,4.3,3.55,6.43,5.34a.52.52,0,0,0,.78,0,34.75,34.75,0,0,0,7-7,.71.71,0,0,0,0-1.06c-1.77-2.1-3.51-4.23-5.27-6.34-.15-.18-.24-.33-.1-.58a22.7,22.7,0,0,0,1.94-4.66.44.44,0,0,1,.45-.37l8.48-.77c.35,0,.46-.18.51-.49A34.32,34.32,0,0,0,82.21,44.92ZM50,64.82A14.82,14.82,0,1,1,64.82,50,14.81,14.81,0,0,1,50,64.82Z"
                  transform="translate(-17.41 -17.41)"
            />
        </svg>
    );
}

export default RegisteredListingIcon;