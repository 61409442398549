import ContentContainer from "../../components/content/ContentContainer";
import Tabs from "../../components/tabs/Tabs";
import MemberInsights from "./MemberInsights/MemberInsights";
import AorMessagesTable from "./AorMessagesTable";

const AorMessages = () => {
	const title = "AOR Center";

	const buildTabContent = () => {
		let tabs = [
			<div key="messages" data-label="Messages/Alerts">
				<div className="mt-4">
					<AorMessagesTable />
				</div>
			</div>,
			<div key="member-insights" data-label="Member Insights">
				<div className="mt-4">
					<MemberInsights />
				</div>
			</div>,
		];
		return tabs;
	};

	return (
		<ContentContainer title={title} actions={null}>
			<Tabs section="aorMessages" persist={true} children={buildTabContent()} />
		</ContentContainer>
	);
};

export default AorMessages;
