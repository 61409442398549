import { RegisteredListingPayload } from "../features/registeredListing/selectors";
import { UserManagerSettings, WebStorageStateStore } from "oidc-client";
import { AdjustmentsIcon, MailOpenIcon, UserIcon } from "@heroicons/react/outline";
import { FC } from "react";

export function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

type statusType = {
	valueID: number;
	lookupGroupID: number;
	displayOrder: number;
	longValue: string;
	shortValue: string;
	standardYN: boolean;
};

export const settingsIcons: { [key: string]: FC<{ className: string }> } = {
	general: AdjustmentsIcon,
	notification: MailOpenIcon,
	application: AdjustmentsIcon,
	profile: UserIcon,
};

export const standardStatusMap: statusType[] = [
	{
		valueID: 1,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Registered",
		shortValue: "A",
		standardYN: true,
	},
	{
		valueID: 2,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Active Under Contract",
		shortValue: "U",
		standardYN: true,
	},
	{
		valueID: 5,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Hold",
		shortValue: "H",
		standardYN: true,
	},
	{
		valueID: 6,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Canceled",
		shortValue: "K",
		standardYN: true,
	},
	{
		valueID: 8,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Pending",
		shortValue: "P",
		standardYN: true,
	},
	{
		valueID: 9,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Closed",
		shortValue: "S",
		standardYN: true,
	},
	{
		valueID: 10,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Withdrawn",
		shortValue: "W",
		standardYN: true,
	},
	{
		valueID: 11,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Expired",
		shortValue: "X",
		standardYN: true,
	},
	{
		valueID: 12,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Deleted",
		shortValue: "Z",
		standardYN: true,
	},
	{
		valueID: 90155,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Incomplete",
		shortValue: "I",
		standardYN: true,
	},
	{
		valueID: 93271,
		lookupGroupID: 1,
		displayOrder: 0,
		longValue: "Coming Soon",
		shortValue: "C",
		standardYN: true,
	},
];

export const prop_types = [
	{
		value: "Resi",
		label: "Residential",
	},
	{
		value: "Land",
		label: "Land",
	},
	{
		value: "Rinc",
		label: "Residential Income",
	},
	{
		value: "Rlse",
		label: "Residential Lease",
	},
	{
		value: "Manu",
		label: "Manufactured in Park",
	},
	{
		value: "Comm",
		label: "Commercial Sale",
	},
	{
		value: "Clse",
		label: "Commercial Lease",
	},
	{
		value: "Busop",
		label: "Business Opportunity",
	},
];

export function propTypeLookupCode(code: string) {
	let p = prop_types.find((o) => o.value === code);

	if (p) {
		return p.label;
	}
	return code;
}

export function propTypeLookupLabel(label: string) {
	let p = prop_types.find((o) => o.label === label);

	if (p) {
		return p.value;
	}
	throw new Error("Invalid property type");
}

export function buildUnparsedAddress(listing: RegisteredListingPayload) {
	let address = [listing.streetNumber, listing.streetName, listing.unitNumber, listing.city, listing.stateOrProvince, listing.postalCode];

	return address.join(" ");
}

export function generateUserManagerConfig(): UserManagerSettings {
	let redirect_uri, silent_redirect_uri, post_logout_uri;
	let root = process.env.REACT_APP_ROOT_URL;
	let auth = process.env.REACT_APP_AUTH_URL;
	let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;

	const queryParameters = new URLSearchParams(window.location.search);
	let loginAor = queryParameters.get("loginAor");

	redirect_uri = `https://${root}/callback`;
	silent_redirect_uri = `https://${root}/silent_renew`;
	post_logout_uri = `https://${root}/logged-out`;

	return {
		client_id: clientId,
		redirect_uri: redirect_uri,
		response_type: "code",
		response_mode: "query",
		scope: "ODataApi openid CrmlsProfile MemberPortalApi offline_access",
		authority: auth,
		silent_redirect_uri: silent_redirect_uri,
		automaticSilentRenew: true,
		filterProtocolClaims: true,
		loadUserInfo: true,
		post_logout_redirect_uri: post_logout_uri,
		monitorSession: false, // this seems to fix logout issue, possibly also related to https://github.com/IdentityModel/oidc-client-js/issues/1319#issuecomment-799944264
		includeIdTokenInSilentRenew: false, // trying this, found at https://github.com/IdentityModel/oidc-client-js/issues/172#issuecomment-593581424
		userStore: new WebStorageStateStore({ store: window.localStorage }), // use local storage - this will allow opening new tabs without being prompted to log in (https://github.com/IdentityModel/oidc-client-js/wiki)
		acr_values: loginAor ? loginAor : "",
	};
}

export function convertUrlToString(url: string) {
	let convertedLabel: any[] = [];
	let convertUrlTab = url.split("-");
	convertUrlTab.forEach((word) => {
		if (word.length > 1) {
			convertedLabel.push(word.charAt(0).toUpperCase() + word.slice(1));
		} else {
			convertedLabel.push(word);
		}
	});
	return convertedLabel.join(" ");
}

export function passwordValidation(value: string) {
	// Password must be at least 8 charcters long and contain at least one uppercase, lowercase, number and special character
	if (value.length >= 8 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)) {
		return true;
	} else {
		return false;
	}
}

export function secondsToReadable(seconds: any) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds - hours * 3600) / 60);
	seconds = seconds - hours * 3600 - minutes * 60;
	if (!!hours) {
		if (!!minutes) {
			return `${hours}h ${minutes}m ${seconds}s`;
		} else {
			return `${hours}h ${seconds}s`;
		}
	}
	if (!!minutes) {
		return `${minutes}m ${seconds}s`;
	}
	return `${seconds} seconds`;
}
